import * as React from "react";
import Layout from "../components/Layout";

class NotFoundPage extends React.Component<any> {
  render() {
    return (
      <Layout>
        <div>
          <h1>NOT FOUND</h1>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
